import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast_position } from "../../Settings/Authorization";
import { toast } from "react-toastify";
import axios from "axios";
import { env } from "../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const ownerViewAllAcc = createAsyncThunk(
  "ownerViewAllAcc",
  async (values, { rejectWithValue }) => {
    const {
      page_no,
      page_size,
      active_status,
      connected_banking,
      search_string,
      type_of_account,
      bank_account_no,
      ifsc,
      pan,
      bank_name,
      sort_by_balance,
    } = values; // Destructure page_no and page_size from values
    try {
      const response = await axios.get(`${baseUrl}/accounts/owner`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          active_status: active_status,
          connected_banking: connected_banking,
          search_string: search_string,
          type_of_account: type_of_account,
          bank_account_no: bank_account_no,
          ifsc: ifsc,
          pan: pan,
          bank_name: bank_name,
          "sort_by_balance[balance]": sort_by_balance
            ? sort_by_balance == "Low to high"
              ? "ascending"
              : "descending"
            : undefined,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const ownerListViewAllAcc = createAsyncThunk(
  "ownerListViewAllAcc",
  async (values, { rejectWithValue }) => {
    const {
      page_no,
      page_size,
      search_string,
      connected_banking,
      type_of_account,
      bank_account_no,
      ifsc,
      pan,
      bank_name,
      sort_by_balance,
      tag,
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/owner`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          search_string: search_string,
          connected_banking: connected_banking,
          type_of_account: type_of_account,
          bank_account_no: bank_account_no,
          ifsc: ifsc,
          pan: pan,
          tag: tag,
          bank_name: bank_name,
          "sort_by_balance[balance]": sort_by_balance
            ? sort_by_balance == "Low to high"
              ? "ascending"
              : "descending"
            : undefined,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const ownerDashboard = createAsyncThunk(
  "ownerDashboard",
  async ({ page, page_size }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/accounts/owner`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page,
          page_size: page_size,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error, toast_position);
      console.error("Error", error);
      return rejectWithValue(error);
    }
  }
);

export const ownerAccDetail = createAsyncThunk(
  "ownerAccDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/accounts/owner/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);

export const getOwnerAccStatement = createAsyncThunk(
  "accounts/getOwnerAccStatement",
  async (
    {
      id,
      start_date,
      end_date,
      page_no,
      credit_debit,
      start_amount,
      end_amount,
      page_size,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${baseUrl}/accounts/owner/${id}/display_statement`,
        {
          start_date: start_date,
          end_date: end_date,
          page_no: page_no,
          credit_debit: credit_debit,
          start_amount: start_amount,
          end_amount: end_amount,
          page_size: page_size,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);

export const getAllOwnerAccStatement = createAsyncThunk(
  "accounts/getAllOwnerAccStatement",
  async (
    {
      id,
      start_date,
      end_date,
      page_no,
      credit_debit,
      start_amount,
      end_amount,
      page_size,
      bank_name,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${baseUrl}/accounts/owner/consolidated_statement`,
        {
          start_date: start_date,
          end_date: end_date,
          page_no: page_no,
          credit_debit: credit_debit,
          start_amount: start_amount,
          end_amount: end_amount,
          page_size: page_size,
          owner_account_id: id,
          bank_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);

export const downloadOwnerStatement = createAsyncThunk(
  "downloadOwnerStatement",
  async ({
    id,
    formatType,
    start_date,
    end_date,
    credit_debit,
    start_amount,
    end_amount,
  }) => {
    const data = {
      start_date,
      end_date,
      credit_debit,
      start_amount,
      end_amount,
    };

    try {
      const response = await fetch(
        `${baseUrl}/accounts/owner/${id}/display_statement_download.${formatType}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      // Simple Explanation of the Flow:
      // Get the file as a blob (a raw file).
      // Create a download URL so the browser can access the file.
      // Create a hidden download link.
      // Set the file name (like statement.pdf).
      // Click the link automatically to trigger the download.
      // Clean up by removing the temporary link and URL.

      let blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `statement.${formatType}`);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return true;
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  }
);

export const downloadAllOwnerStatement = createAsyncThunk(
  "downloadAllOwnerStatement",
  async ({
    id,
    formatType,
    start_date,
    end_date,
    credit_debit,
    start_amount,
    end_amount,
    bank_name,
  }) => {
    const data = {
      owner_account_id: id,
      start_date,
      end_date,
      credit_debit,
      start_amount,
      end_amount,
      bank_name,
    };

    try {
      const response = await fetch(
        `${baseUrl}/accounts/owner/consolidated_statement_download.${formatType}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      let blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `statement.${formatType}`);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return true;
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  }
);

export const addOwnerAccTags = createAsyncThunk(
  "accounts/addOwnerAccTags",
  async ({ owner_id, tags }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/accounts/owner/add_tag`,
        {
          owner_id,
          tags,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);
export const removeOwnerAccTags = createAsyncThunk(
  "accounts/removeOwnerAccTags",
  async ({ owner_id, tags }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/accounts/owner/remove_tag`,
        {
          owner_id,
          tags,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);

export const getOwnerAccBalance = createAsyncThunk(
  "accounts/getOwnerAccBalance",
  async (id) => {
    try {
      const response = await fetch(`${baseUrl}/accounts/owner/${id}/balance`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      if (!response.ok) {
        throw new Error(
          "Currently unable to fetch balance details.Please try again later"
        );
      }
      const data = await response.json();

      return data;
    } catch (error) {
      toast.error(error);
      throw error;
    }
  }
);

export const getCreateTransactionBalance = createAsyncThunk(
  "accounts/getCreateTransactionBalance",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/accounts/owner/${id}/balance`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      if (!response.ok) {
        throw new Error("Sorry! Your token is expired. Please login again");
      }
      const data = await response.json();

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAllOwnerAccBalance = createAsyncThunk(
  "refreshAllOwnerAccBalance",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/accounts/owner/${id}/balance`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      return data;
    } catch (error) {
      toast.error(error);
      return rejectWithValue(error);
    }
  }
);
export const getOwnerAccountIFSCDetails = createAsyncThunk(
  "getOwnerAccountIFSCDetails",
  async (values) => {
    try {
      const response = await axios.get(
        `${baseUrl}/verification/ifsc/` + values?.search_string,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

const ownerAllVerification = createSlice({
  name: "ownerAllVerification",
  initialState: {
    toggle: false,
    storedPage: 1,
    ownerAccountAvailabletags: [],

    addOwnerAccTagsLoading: true,
    addOwnerAccTagsData: [],
    addOwnerAccTagsError: false,

    removeOwnerAccTagsLoading: true,
    removeOwnerAccTagsData: [],
    removeOwnerAccTagsError: false,

    ownerViewAllAccData: null,
    ownerViewAllAccLoading: null,
    ownerViewAllAccError: false,
    ownerViewAllAccErrorData: null,

    ownerViewAllListAccData: null,
    ownerViewAllListAccLoading: null,
    ownerViewAllListAccError: false,
    ownerViewAllListAccErrorData: null,

    ownerDashboardAccData: null,
    ownerDashboardAccLoading: null,
    ownerDashboardAccError: null,

    ownerAccDetailData: null,
    ownerAccDetailLoading: null,
    ownerAccDetailError: null,

    ownerAccBalanceLoading: false,
    ownerAccBalanceError: null,
    ownerAllAccBalanceLoading: false,
    ownerAllAccBalanceError: false,
    ownerAccBalanceData: null,
    showSuccessToast: false,

    ownerAccStatementLoading: true,
    ownerAccStatementError: false,
    ownerAccStatementData: null,

    ownerAllAccStatementLoading: true,
    ownerAllAccStatementError: false,
    ownerAllAccStatementData: null,

    createTransactionBankRefreshLoading: null,
    createTransactionBankRefreshData: null,
    createTransactionBankRefreshError: null,

    ownerAccIfscLoading: false,
    ownerAccIfscError: false,
    ownerAccIfscData: null,

    width: window.innerWidth,
    tokenExpire: false,
  },
  reducers: {
    setWindowWidth: (state) => {
      state.width = window.innerWidth;
    },
    setshowsuccestoast: (state) => {
      state.showSuccessToast = !state.showSuccessToast;
    },
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    setTags: (state, action) => {
      state.ownerAccountAvailabletags = action.payload;
    },
    setOwnerAllAccStatementLoading: (state, action) => {
      state.ownerAllAccStatementLoading = action.payload;
    },

    resetpage: (state) => {
      state.storedPage = 1;
    },
    resetToggle: (state) => {
      state.toggle = false;
    },
    setStoredPage: (state, action) => {
      state.storedPage = action.payload;
    },
    resetOwnerBalance: (state) => {
      state.createTransactionBankRefreshError = null;
      state.createTransactionBankRefreshData = null;
    },
    resetLoading: (state) => {
      state.ownerAccBalanceLoading = false;
    },
    resetTokenExpire: (state) => {
      state.tokenExpire = false;
    },
    resetStatementData: (state) => {
      state.ownerAccStatementData = null;
      state.ownerAllAccStatementData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addOwnerAccTags.pending, (state, action) => {
      state.tokenExpire = false;
      state.addOwnerAccTagsLoading = true;
    });
    builder.addCase(addOwnerAccTags.fulfilled, (state, action) => {
      state.tokenExpire = false;
      state.addOwnerAccTagsLoading = false;
      state.addOwnerAccTagsData = action.payload;
    });
    builder.addCase(addOwnerAccTags.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.addOwnerAccTagsData = null;
      state.addOwnerAccTagsError = action.payload;
      state.addOwnerAccTagsLoading = true;
    });

    builder.addCase(removeOwnerAccTags.pending, (state, action) => {
      state.tokenExpire = false;
      state.removeOwnerAccTagsLoading = true;
    });
    builder.addCase(removeOwnerAccTags.fulfilled, (state, action) => {
      state.tokenExpire = false;
      state.removeOwnerAccTagsLoading = false;
      state.removeOwnerAccTagsData = action.payload;
    });
    builder.addCase(removeOwnerAccTags.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.removeOwnerAccTagsData = null;
      state.removeOwnerAccTagsError = action.payload;
      state.removeOwnerAccTagsLoading = true;
    });
    builder.addCase(ownerDashboard.pending, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      state.ownerDashboardAccLoading = true;
    });
    builder.addCase(ownerDashboard.fulfilled, (state, action) => {
      state.ownerAccountAvailabletags =
        action?.payload?.summary?.available_tags;
      if (action?.payload?.summary?.available_tags) {
        sessionStorage.setItem(
          "AvailableTags",
          JSON.stringify(action?.payload?.summary?.available_tags || [])
        );
      }

      state.ownerDashboardAccLoading = false;
      state.ownerDashboardAccData = action.payload;
    });
    builder.addCase(ownerDashboard.rejected, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      state.ownerDashboardAccError = true;
      state.ownerDashboardAccData = null;
    });
    builder.addCase(ownerViewAllAcc.pending, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      state.tokenExpire = false;
      state.ownerViewAllAccError = null;
      state.ownerViewAllAccErrorData = null;
      state.ownerViewAllAccLoading = true;
    });
    builder.addCase(ownerViewAllAcc.fulfilled, (state, action) => {
      state.ownerAccountAvailabletags =
        action?.payload?.summary?.available_tags;
      if (action?.payload?.summary?.available_tags) {
        sessionStorage.setItem(
          "AvailableTags",
          JSON.stringify(action?.payload?.summary?.available_tags || [])
        );
      }
      state.tokenExpire = false;
      state.ownerViewAllAccLoading = false;
      state.ownerViewAllAccData = action.payload;
      state.ownerViewAllAccError = false;
      state.ownerViewAllAccErrorData = null;
    });
    builder.addCase(ownerViewAllAcc.rejected, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerViewAllAccError = true;
      state.ownerViewAllAccData = null;
      state.ownerViewAllAccErrorData = action.payload;
    });
    builder.addCase(ownerListViewAllAcc.pending, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      state.tokenExpire = false;
      state.ownerViewAllListAccError = null;
      state.ownerViewAllListAccErrorData = null;
      state.ownerViewAllListAccLoading = true;
    });
    builder.addCase(ownerListViewAllAcc.fulfilled, (state, action) => {
      state.ownerAccountAvailabletags =
        action?.payload?.summary?.available_tags;
      if (action?.payload?.summary?.available_tags) {
        sessionStorage.setItem(
          "AvailableTags",
          JSON.stringify(action?.payload?.summary?.available_tags || [])
        );
      }

      state.tokenExpire = false;
      state.ownerViewAllListAccLoading = false;
      state.ownerViewAllListAccData = action.payload;
      state.ownerViewAllListAccError = false;
      state.ownerViewAllListAccErrorData = null;
    });
    builder.addCase(ownerListViewAllAcc.rejected, (state, action) => {
      if (action?.payload?.summary?.available_tags) {
        state.ownerAccountAvailabletags = [];
      }
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerViewAllListAccError = true;
      state.ownerViewAllListAccData = null;
      state.ownerViewAllListAccErrorData = action.payload;
    });
    builder.addCase(ownerAccDetail.pending, (state, action) => {
      state.ownerAccDetailLoading = true;
    });
    builder.addCase(ownerAccDetail.fulfilled, (state, action) => {
      state.ownerAccDetailLoading = false;
      state.ownerAccDetailData = action.payload;
      state.ownerAccDetailError = null;
    });
    builder.addCase(ownerAccDetail.rejected, (state, action) => {
      state.ownerAccDetailError = action.payload;
      console.log(state.ownerAccDetailError, "state.ownerAccDetailError");
      state.ownerAccDetailData = null;
      state.ownerAccDetailLoading = false;
    });
    builder.addCase(getOwnerAccBalance.pending, (state, action) => {
      state.ownerAccBalanceLoading = true;
    });
    builder.addCase(getOwnerAccBalance.fulfilled, (state, action) => {
      state.ownerAccBalanceLoading = false;
      state.showSuccessToast = true;
      state.ownerAccBalanceData = action.payload;
    });
    builder.addCase(getOwnerAccBalance.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerAccBalanceLoading = false;
      state.ownerAccBalanceError = true;
      state.showSuccessToast = false;
      state.ownerAccBalanceData = null;
    });
    builder.addCase(getCreateTransactionBalance.pending, (state, action) => {
      state.createTransactionBankRefreshLoading = true;
    });
    builder.addCase(getCreateTransactionBalance.fulfilled, (state, action) => {
      state.createTransactionBankRefreshLoading = false;
      state.createTransactionBankRefreshData = action.payload;
    });
    builder.addCase(getCreateTransactionBalance.rejected, (state, action) => {
      state.createTransactionBankRefreshLoading = false;
      state.createTransactionBankRefreshError = action.payload;
      state.createTransactionBankRefreshData = null;
    });
    builder.addCase(refreshAllOwnerAccBalance.pending, (state, action) => {
      state.ownerAllAccBalanceLoading = true;
    });
    builder.addCase(refreshAllOwnerAccBalance.fulfilled, (state, action) => {
      state.ownerAllAccBalanceLoading = false;
    });
    builder.addCase(refreshAllOwnerAccBalance.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerAllAccBalanceError = true;
      state.ownerAllAccBalanceLoading = false;
    });
    builder.addCase(getOwnerAccStatement.pending, (state, action) => {
      state.ownerAccStatementLoading = true;
    });
    builder.addCase(getOwnerAccStatement.fulfilled, (state, action) => {
      state.ownerAccStatementLoading = false;
      state.ownerAccStatementError = false;
      state.ownerAccStatementData = action.payload;
    });
    builder.addCase(getOwnerAccStatement.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerAccStatementData = null;
      state.ownerAccStatementError = true;
      state.ownerAccStatementLoading = false;
    });
    builder.addCase(getAllOwnerAccStatement.pending, (state, action) => {
      state.ownerAllAccStatementLoading = true;
    });
    builder.addCase(getAllOwnerAccStatement.fulfilled, (state, action) => {
      state.ownerAllAccStatementLoading = false;
      state.ownerAllAccStatementError = false;
      state.ownerAllAccStatementData = action.payload;
    });
    builder.addCase(getAllOwnerAccStatement.rejected, (state, action) => {
      if (action?.payload?.response?.data?.errors?.token_expired) {
        state.tokenExpire = true;
      }
      state.ownerAllAccStatementData = null;
      state.ownerAllAccStatementError = true;
      state.ownerAllAccStatementLoading = false;
    });

    builder.addCase(downloadOwnerStatement.pending, (state, action) => {
      state.ownerAccStatementDownloadLoading = true;
    });
    builder.addCase(downloadOwnerStatement.fulfilled, (state, action) => {
      state.ownerAccStatementDownloadLoading = false;
      state.ownerAccStatementDownloadStatus = action.payload;
    });

    builder.addCase(downloadOwnerStatement.rejected, (state, action) => {
      state.ownerAccStatementDownloadLoading = false;
      state.ownerAccStatementDownloadError = action.payload;
    });

    builder.addCase(getOwnerAccountIFSCDetails.pending, (state, action) => {
      state.ownerAccIfscLoading = true;
    });
    builder.addCase(getOwnerAccountIFSCDetails.fulfilled, (state, action) => {
      state.ownerAccIfscLoading = false;
      state.ownerAccIfscData = action.payload;
    });
    builder.addCase(getOwnerAccountIFSCDetails.rejected, (state, action) => {
      state.ownerAccIfscError = true;
      state.ownerAccIfscLoading = false;
    });
  },
});

export const {
  setWindowWidth,
  setshowsuccestoast,
  setToggle,
  resetpage,
  setStoredPage,
  resetOwnerBalance,
  resetLoading,
  resetTokenExpire,
  resetToggle,
  setTags,
  resetStatementData,
  setOwnerAllAccStatementLoading,
} = ownerAllVerification.actions;

export default ownerAllVerification.reducer;
