import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  beneCategories,
  beneWaitingApproved,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import ArrowDown from "../../../assets/OwnerAccount/arrowdown5.png";

import cancel from "../../../assets/TransactionDashboardIcons/cancel.png";
import search from "../../../assets/ViewAllTransactions/search-white.png";
import arrowforward from "../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import "./index.css";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import DatePickerComponent from "../../_utils/Calendar";
import { tooltipClasses, Tooltip, Button } from "@mui/material";
// import BeneTxnViewModal from "../BeneTxnViewModal";
import { checkUsertoken } from "../../../redux/features/login/login";
import { ViewAgenda } from "@mui/icons-material";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";

function InternalTxnFilterSidebar({
  txnHistorytype,
  isOpen,
  onClose,
  sendCategory,
  setPage,
  setIsSidebarOpen,
  sendDateRange,
  initCategory,
  setInitCategory,
  sendPaymentMethod,
  setStartDateFilter,
  setEndDateFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setPaymentMethod,
  paymentMethod,
  setSelectedBeneAccId,
  beneAccountId,
  setBeneAccountId,
  accountName,
  setAccountName,
  showDatePicker,
  setShowDatePicker,
  setIsFilterApplied,
  categoryDropdownRef,
  settlementDropdownRef,
  paymentDropdownRef,
  dateRef,
  dropdownOpen,
  setDropdownOpen,
  isDropdownPaymentOpen,
  setDropdownPaymentOpen,
  isDropdownOpen,
  setIsDropdownOpen,
  handleOpenViewModal,
  isViewModalOpen,
  viewModalRef,
  setTxnPayLoad,
  txnPayLoad,
  handleOpenViewModalOwner,
  selectedOwnerAccId,
  setSelectedOwnerAccId,
  accountNumber,
  setAccountNumber,
  txnAmountFilter,
  setTxnAmountFilter,
  startDateFilter,
  endDateFilter,
  setTempEndDate,
  setTempStartDate,
  handleFilterDateRange,
  tempEndDate,
  tempStartDate,
  currentStatus,
  setCurrentStatus,
  isPaymentMethod,
  currentCategory,
  selectedBeneAccId,
  balanceType,
  filterRef,
  internalAccount,
}) {
  const [settlementSearch, setSettlementSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [sideDrawer, setSideDrawer] = useState(true);
  const [BeneTxnSideDrawerLoader, SetbeneTxnSideDrawerLoder] = useState(false);
  const [DrawerData, setDrawerData] = useState({});
  const [showDeclinedPopup, setshowDeclinedPopup] = useState(false);
  const [deniedTxnId, SetdeniedTxnId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, SetrefreshId] = useState("");
  const [scrollAmount, setScrollAmount] = useState(0);
  const [isDateConfirmed, setIsDateConfirmed] = useState(false);

  const [activeSection, setActiveSection] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const categories = useSelector(
    (state) => state?.beneAllVerification.beneTransCategories?.categories
  );

  const dispatch = useDispatch();

  // const settlementDropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const yearDropdownRef = useRef(null);
  const applyBtnRef = useRef(null);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
  };
  const handleSettlementClick = (settlement) => {
    setPaymentMethod(settlement == "All" ? "" : settlement);
    setPage(1);
  };
  const {
    viewAccountDetailData,
    viewAccountDetailLoading,
    achivedDataDetail,
    unachivedLoadingDetail,
    achivedLoadingDetail,
    unachivedDataDetail,
  } = useSelector((state) => state.verify);
  const { beneTxnAccountLoading, beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        settlementDropdownRef.current &&
        !settlementDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        paymentDropdownRef.current &&
        !paymentDropdownRef.current.contains(event.target)
      ) {
        setDropdownPaymentOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoryDropdownRef, settlementDropdownRef, dateRef, paymentDropdownRef]);

  useEffect(() => {
    if (viewAccountDetailData) {
      setDrawerData(viewAccountDetailData?.beneficiary_accounts[0]);
    }
  }, [viewAccountDetailData]);
  const handleScroll = (scrollAmount) => {
    window.scrollBy({
      top: scrollAmount,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 13: // enter key
          isOpen && applyBtnRef.current.click();
          break;
        case 83: // s key
          setScrollAmount(30);
          break;
        case 87: // w key
          setScrollAmount(-30);
          break;
        default:
          break;
      }
    };

    const handleKeyUp = () => {
      setScrollAmount(0);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    const scrollInterval = setInterval(() => {
      if (scrollAmount !== 0) {
        handleScroll(scrollAmount);
      }
    }, 10);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(scrollInterval);
    };
  }, [scrollAmount, isOpen]);
  const handleConfirm = () => {
    setShowDatePicker(false);
    setIsDateConfirmed(true);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setIsDateConfirmed(false);
    setStartDate(null);
    setEndDate(null);
  };
  const clearFilter = () => {
    setInitCategory("");
    // setIsPaymentMethod("");
    setPaymentMethod("");
    setIsDropdownOpen(false);
    setCategorySearch("");
    setSettlementSearch("");
    // setIsSidebarOpen(false);
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    setTempEndDate("");
    setTempStartDate("");
    sendPaymentMethod("");
    sendCategory("");
    setStartDateFilter("");
    setEndDateFilter("");
    setTxnAmountFilter("");
    setAccountName("");
    setIsFilterApplied(
      txnHistorytype === "internalTxnHistory" ||
        txnHistorytype === "ownerDetailTxnHistory"
        ? true
        : false
    );
    if (
      txnHistorytype !== "internalTxnHistory" &&
      txnHistorytype !== "ownerDetailTxnHistory"
    ) {
      setSelectedOwnerAccId("");
      setAccountNumber("");
    } else if (
      txnHistorytype === "ownerDetailTxnHistory" &&
      balanceType == "credit"
    ) {
      setSelectedOwnerAccId("");
    }

    if (
      txnHistorytype !== "ownerDetailTxnHistory" &&
      balanceType !== "credit"
    ) {
      setBeneAccountId("");
      setSelectedBeneAccId("");
      setAccountNumber("");
    }
    setTxnPayLoad({
      txnPayLoad,
      amount: "",
      ownerAccountName: "",
      ownerAccountId: "",
      ownerbankAccountIFSC: "",
      ownerbankAccountNo: "",
    });
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };
  const filterCategory = () => {
    // setTxnPayLoad({ ...txnPayLoad });
    if (
      txnHistorytype !== "internalTxnHistory" &&
      txnHistorytype !== "ownerDetailTxnHistory"
    ) {
      setSelectedOwnerAccId(txnPayLoad?.ownerAccountId);
      setAccountNumber(txnPayLoad?.ownerbankAccountNo);
    } else if (
      txnHistorytype === "ownerDetailTxnHistory" &&
      balanceType === "credit"
    ) {
      setSelectedOwnerAccId(txnPayLoad?.ownerAccountId);
    }
    setTxnAmountFilter(txnPayLoad?.amount);
    setPage(1);

    handleFilterDateRange(convertDate(startDate), convertDate(endDate));
    setTempStartDate(startDateFilter);

    setTempEndDate(endDateFilter);
    sendPaymentMethod(paymentMethod);
    if (txnHistorytype !== "ownerDetailTxnHistory" && balanceType !== "debit") {
      setSelectedBeneAccId(beneAccountId);
    }
    // setTxnAmount(txnAmount);
    setShowDatePicker(false);
    setIsSidebarOpen(false);
  };

  const handleTxnSearch = (text) => {
    const sanitizedText = text
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    if (sanitizedText === "") {
      if (txnAmountFilter.length === 0) {
        setTxnAmountFilter("");
      }
      setTxnPayLoad({
        ...txnPayLoad,
        amount: "",
      });
      return;
    }

    const amount = parseFloat(sanitizedText);
    if (!isNaN(amount)) {
      setTxnPayLoad({
        ...txnPayLoad,
        amount: sanitizedText,
      });
    }
  };

  const filteredSettlements = ["All", "NEFT", "RTGS"].filter((settlement) =>
    settlement.toLowerCase().includes(settlementSearch.toLowerCase())
  );
  const handleCategorySearchChange = (event) => {
    setCategorySearch(event.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const additional_Data = beneTxnAccountData?.transaction[0]?.additional_data;

  const handleApproval = async (id) => {
    SetbeneTxnSideDrawerLoder(true);
    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));

    if (data) {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                // item.approval_status === "approved" ? "pending" : "approved",
                item.approval_status === "pending" ? "approved" : "pending",
              status: data?.payload.status,
            };
          }
          return item;
        }
      );
      setDrawerData(updatedData[0]);
    }

    setTimeout(() => {
      SetbeneTxnSideDrawerLoder(false);
      setRefreshData(true);
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        window.location.reload();
      }
    }, 2000);
  };

  useEffect(() => {
    setRefreshData(false);
  }, [refreshData, dispatch]);

  const handleDeclinedTxn = (id) => {
    setshowDeclinedPopup(true);
    SetdeniedTxnId(id);
  };
  const handleClosePopup = () => {
    setshowDeclinedPopup(false);
  };

  const handleSettlementSearchChange = (event) => {
    setSettlementSearch(event.target.value);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsDateConfirmed(false);
  };

  const handleFilterApply = () => {
    if (
      ((txnHistorytype === "internalTxnHistory" ||
        txnHistorytype === "ownerDetailTxnHistory") &&
        selectedOwnerAccId?.length > 0 &&
        accountNumber &&
        (paymentMethod ||
          txnPayLoad?.amount.length > 0 ||
          txnAmountFilter.length > 0 ||
          txnPayLoad?.ownerAccountId ||
          isPaymentMethod?.length > 0)) ||
      (startDate && endDate && isDateConfirmed)
    ) {
      return true;
    }

    if (
      (paymentMethod ||
        txnPayLoad?.amount.length > 0 ||
        txnAmountFilter.length > 0 ||
        txnPayLoad?.ownerAccountId ||
        isPaymentMethod?.length > 0 ||
        selectedOwnerAccId?.length > 0) &&
      startDate &&
      endDate &&
      isDateConfirmed
    ) {
      return true;
    }

    if (txnPayLoad?.amount.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (txnAmountFilter.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (txnPayLoad?.ownerAccountId && !startDate && !endDate) {
      return true;
    }

    if (paymentMethod && !startDate && !endDate) {
      return true;
    }
    if (isPaymentMethod?.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (selectedOwnerAccId?.length > 0 && !startDate && !endDate) {
      return true;
    }

    if (startDateFilter && endDateFilter) {
      return true;
    }

    if (startDate && endDate && isDateConfirmed) {
      return true;
    }

    return false;
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {isMobile ? (
        <>
          <div
            // onClose={toggleSidebar(false)}
            className={`internalTxnviewallsidebar ${
              isOpen ? "open" : "closed"
            }`}
            style={{
              backgroundColor: "#314c7f",
              pointerEvents: isViewModalOpen ? "none" : "",
              position:
                txnHistorytype === "internalTxnHistory" ? "absolute" : "fixed",
              marginTop:
                txnHistorytype === "internalTxnHistory"
                  ? "60px"
                  : txnHistorytype === "ownerDetailTxnHistory" &&
                    !internalAccount
                  ? "190px"
                  : "160px",
            }}
          >
            <div
              style={{
                height: "100vh",
                overflow: "hidden",
                backgroundColor: "#314c7f",
              }}
            >
              <div
                className="internalTxnviewallFilterDiv px-5"
                style={{
                  visibility: isOpen ? "visible" : "hidden",
                  transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
                  opacity: isOpen ? "1" : "0",
                  borderTop: "2px solid rgb(29, 58, 109)",
                  flexDirection: "column",
                  justifyContent: "unset",
                  height:
                    txnHistorytype === "ownerDetailTxnHistory"
                      ? "70vh"
                      : "65vh",
                  // height: "72vh",
                  overflowY: "auto",
                  paddingBottom: "30px",
                }}
              >
                <span className="text-[#ff5860] font-normal text-[14px]">
                  Filter{" "}
                </span>

                {!isDropdownOpen && (
                  <div
                    className="relative w-[100%] "
                    style={{ height: "64px" }}
                  >
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Payment method
                    </span>

                    <div
                      className="internalTxn_payment_select mt-[10px]"
                      ref={paymentDropdownRef}
                      onClick={(e) => {
                        setDropdownPaymentOpen(!isDropdownPaymentOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        height: isDropdownPaymentOpen ? "160px" : "35px",
                        outline: isDropdownPaymentOpen
                          ? "1px solid #E25869"
                          : "",
                        transition: isDropdownPaymentOpen ? "none" : "",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                        width: "100%",
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="paymentTxn_category_select_scroll_line"
                          style={{
                            display: isDropdownPaymentOpen ? "block" : "none",
                            width: "97%",
                          }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: isDropdownPaymentOpen ? "9px" : "15px",
                            color: paymentMethod ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {paymentMethod === "" ? "Select type" : paymentMethod}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: isDropdownPaymentOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {isDropdownPaymentOpen && (
                        <div className="bg-[#667CA3] absolute top-12 flex flex-col items-start rounded-[10px] w-full h-[82px] px-1">
                          {["All", "NEFT", "RTGS"].map((settlement) => (
                            <div
                              key={settlement}
                              className="flex w-full h-[35px] hover:bg-[#506994] cursor-pointer rounded-[5px] border-l-transparent"
                              style={{
                                backgroundColor:
                                  paymentMethod === settlement && paymentMethod
                                    ? "#3A5686"
                                    : "",
                                padding: "0px 10px",
                              }}
                              onClick={(e) => {
                                handleSettlementClick(settlement);
                                setIsDropdownOpen(false);
                              }}
                            >
                              <span className="pl-2 py-2.5 text-[#dadada] text-xs truncate w-[90%] ">
                                {settlement}
                              </span>
                              <div
                                style={{
                                  display:
                                    paymentMethod == settlement
                                      ? "flex"
                                      : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "14px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="relative w-[100%]" style={{ height: "64px" }}>
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Destination account
                  </span>
                  <div
                    ref={viewModalRef}
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[100%] mt-[5px]"
                    style={{
                      opacity:
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit"
                          ? "0.5"
                          : currentStatus === "require approval"
                          ? "0.5"
                          : "0.5",
                      cursor:
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit"
                          ? "default"
                          : currentStatus === "require approval"
                          ? "default"
                          : "default",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      // onClick={handleOpenViewModalOwner}
                      onClick={() => {
                        if (
                          txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType === "credit"
                        ) {
                          return;
                        } else {
                          toast.info("Coming Soon...", toast_position);
                        }
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType === "credit"
                            ? "default"
                            : currentStatus === "require approval"
                            ? "default"
                            : "default",
                      }}
                    >
                      <span
                        className="internalTxn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#f9f9f9",
                        }}
                      >
                        {txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit" &&
                        accountNumber
                          ? formatAccountNumber(accountNumber)
                          : "Select owner account"}
                        {/* {txnPayLoad?.ownerbankAccountNo === ""
                      ? "Select owner account"
                      : txnPayLoad?.ownerbankAccountNo} */}
                      </span>
                      <img
                        className="internalTxn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div className="relative w-[100%]" style={{ height: "64px" }}>
                  <span
                    style={{
                      color: "#dadada",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Source account
                  </span>
                  <div
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[100%px] mt-[5px]"
                    style={{
                      opacity:
                        txnHistorytype !== "internalTxnHistory" &&
                        txnHistorytype !== "ownerDetailTxnHistory"
                          ? "0.5"
                          : txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "credit"
                          ? "1"
                          : currentStatus === "require approval"
                          ? "0.5"
                          : "0.5",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      onClick={(e) => {
                        if (isMobile) {
                          toast.info("Coming Soon...", toast_position);
                        } else if (txnHistorytype !== "internalTxnHistory") {
                          e.stopPropagation();
                          handleOpenViewModalOwner();
                        }
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          txnHistorytype === "internalTxnHistory" ||
                          (txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "debit")
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <span
                        className="internalTxn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#f9f9f9",
                        }}
                      >
                        {txnHistorytype === "internalTxnHistory" &&
                        accountNumber
                          ? formatAccountNumber(accountNumber)
                          : txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "debit" &&
                            accountNumber
                          ? formatAccountNumber(accountNumber)
                          : txnPayLoad?.ownerbankAccountNo === ""
                          ? "Select owner account"
                          : formatAccountNumber(txnPayLoad?.ownerbankAccountNo)}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>

                  {/* <div className="relative w-[200px] mt-5">
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                IFSC code
              </span>
              <input
                className="w-[216px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                maxlength="11"
                type="text"
                name="ifscnumber"
                placeholder="Enter IFSC code"
                value=""
                // onChange={(e) => handleTxnSearch(e.target.value)}
              />
            </div>
            <div>
              <p class="internalTxn_number_text">
                Note : Account number and IFSC code <br />
                must be filled to filter by bank details
              </p>
            </div> */}
                </div>

                <div
                  className={`relative w-[100%]`}
                  style={{
                    height: "64px",
                  }}
                >
                  <span
                    style={{
                      color: "#dadada",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Amount
                  </span>

                  <div
                    className="w-[100%] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" ? "0.3" : "1",
                      outline: "none",
                    }}
                  >
                    &#8377;
                    <input
                      className="ml-[3px] bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA]"
                      type="text"
                      placeholder="Enter transaction amount"
                      value={
                        txnPayLoad?.amount.length > 0 ? txnPayLoad?.amount : ""
                      }
                      name="txnamount"
                      onChange={(e) => handleTxnSearch(e.target.value)}
                      onFocus={() => setActiveSection("txnamount")}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    />
                  </div>
                </div>
                <div>
                  <span
                    style={{
                      color: "#dadada",
                      width: "62px",
                      height: "17px",
                      font: "normal normal 12px/19px Open Sans",
                      marginBottom: "5px",
                    }}
                  >
                    Date range
                  </span>
                  <div
                    className="internalTxn_Calendar_main_div flex flex-col items-center bg-[#667CA3] top-[86px]"
                    // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    ref={dateRef}
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "35px",
                      border: showDatePicker ? "1px solid #ff5860" : "none",
                      // borderRadius: showDatePicker ? "10px" : "auto",
                    }}
                  >
                    <div
                      className="flex flex-row items-center justify-between rounded-xl bg-[#667CA3]"
                      style={{
                        width: "305px",
                        height: showDatePicker ? "" : "35px",
                        // border: showDatePicker ? "none" : "1px solid #ff5860",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                        cursor:
                          currentStatus === "require approval"
                            ? "default"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        setShowDatePicker(!showDatePicker);
                        setDropdownOpen(false);
                        setIsDropdownOpen(false);
                        setActiveSection("date");
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    >
                      <div
                        className="internalTxn_date_heading_div cursor-pointer"
                        style={{
                          borderTopLeftRadius: showDatePicker ? "7px" : "none",
                          borderLeft: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="internalTxn_date_heading_left">
                          {startDate
                            ? formatDate(startDate)
                            : "Select from date"}
                        </div>
                      </div>
                      <img
                        src={arrowforward}
                        className="internalTxn_viewall_calendar_icon"
                      />
                      <div
                        className="internalTxn_date_select_div cursor-pointer"
                        style={{
                          borderTopRightRadius: showDatePicker ? "7px" : "none",
                          borderRight: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="internalTxn_date_heading_right">
                          {" "}
                          {endDate ? formatDate(endDate) : "Select to date"}
                        </div>
                      </div>
                    </div>
                    {showDatePicker && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <DatePickerComponent
                          startDate={startDate}
                          endDate={endDate}
                          handleDateChange={handleDateChange}
                          handleCancel={handleCancel}
                          handleConfirm={handleConfirm}
                          showDatePicker={showDatePicker}
                          yearDropdownRef={yearDropdownRef}
                          dateRef={dateRef}
                          setShowDatePicker={setShowDatePicker}
                          isOpen={isOpen}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="internalTxnFilterbuttonContainer"
              style={{
                padding: "0",
                height: "50px",
                backgroundColor: "#506994",
                borderTopLeftRadius: "22px",
                borderTopRightRadius: "22px",
                bottom: "0",
                position: "fixed",
                width: "100%",
                zIndex: "99999",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="acc-viewall-clear-filter border border-[#F9F9F9] text-[#f9f9f9] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                onClick={() => {
                  if (
                    txnHistorytype === "internalTxnHistory" &&
                    accountNumber &&
                    selectedOwnerAccId?.length > 0 &&
                    (paymentMethod ||
                      startDate ||
                      endDate ||
                      txnPayLoad?.amount.length > 0 ||
                      txnPayLoad?.ownerAccountId ||
                      isPaymentMethod?.length > 0 ||
                      txnAmountFilter?.length > 0 ||
                      (startDateFilter && endDateFilter))
                  ) {
                    clearFilter();
                  } else if (
                    txnHistorytype === "ownerDetailTxnHistory" &&
                    balanceType === "credit"
                  ) {
                    if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "credit" &&
                      accountNumber &&
                      selectedBeneAccId?.length > 0 &&
                      (paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        (startDate && endDate) ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDateFilter && endDateFilter))
                    ) {
                      clearFilter();
                    }
                  } else if (
                    txnHistorytype === "ownerDetailTxnHistory" &&
                    balanceType === "debit"
                  ) {
                    if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "debit" &&
                      accountNumber &&
                      selectedOwnerAccId?.length > 0 &&
                      (paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        (startDate && endDate) ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDateFilter && endDateFilter))
                    ) {
                      clearFilter();
                    }
                  } else if (
                    (txnHistorytype !== "internalTxnHistory" &&
                      !accountNumber &&
                      paymentMethod) ||
                    startDate ||
                    endDate ||
                    txnPayLoad?.amount.length > 0 ||
                    txnPayLoad?.ownerAccountId ||
                    isPaymentMethod?.length > 0 ||
                    txnAmountFilter?.length > 0 ||
                    selectedOwnerAccId?.length > 0 ||
                    (startDateFilter && endDateFilter)
                  ) {
                    clearFilter();
                  }
                }}
                style={{
                  cursor:
                    txnHistorytype === "internalTxnHistory"
                      ? txnHistorytype === "internalTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "pointer"
                        : "default"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "credit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "pointer"
                        : "default"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "debit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "pointer"
                        : "default"
                      : paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        (startDate && endDate) ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                      ? "pointer"
                      : "default",
                  opacity:
                    txnHistorytype === "internalTxnHistory"
                      ? txnHistorytype === "internalTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "1"
                        : "0.6"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "credit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "1"
                        : "0.6"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "debit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                        ? "1"
                        : "0.6"
                      : paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        (startDate && endDate) ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                      ? "1"
                      : "0.6",
                }}
              >
                Clear
              </div>
              <div
                className="acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]"
                onClick={() => {
                  if (txnHistorytype === "internalTxnHistory") {
                    if (
                      txnHistorytype === "internalTxnHistory" &&
                      accountNumber &&
                      selectedOwnerAccId?.length > 0 &&
                      (paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDate && endDate && isDateConfirmed))
                    ) {
                      filterCategory();
                    }
                  } else if (
                    txnHistorytype === "ownerDetailTxnHistory" &&
                    balanceType === "credit"
                  ) {
                    if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "credit" &&
                      accountNumber &&
                      selectedBeneAccId?.length > 0 &&
                      (paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDate && endDate && isDateConfirmed))
                    ) {
                      filterCategory();
                    }
                  } else if (
                    txnHistorytype === "ownerDetailTxnHistory" &&
                    balanceType === "debit"
                  ) {
                    if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "debit" &&
                      accountNumber &&
                      selectedOwnerAccId?.length > 0 &&
                      (paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.beneAccountId ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDate && endDate && isDateConfirmed))
                    ) {
                      filterCategory();
                    }
                  } else if (handleFilterApply()) {
                    filterCategory();
                  }
                  // if (
                  // paymentMethod ||
                  // txnPayLoad?.amount.length > 0 ||
                  // txnAmountFilter.length > 0 ||
                  // txnPayLoad?.ownerAccountId ||
                  // ((startDate || endDate) && isDateConfirmed) ||
                  // isPaymentMethod?.length > 0 ||
                  // txnAmountFilter?.length > 0 ||
                  // selectedOwnerAccId?.length > 0 ||
                  // (startDateFilter && endDateFilter)
                  //   handleFilterApply()
                  // ) {
                  //   filterCategory();
                  // }
                }}
                style={{
                  backgroundColor:
                    // paymentMethod ||
                    // txnPayLoad?.amount.length > 0 ||
                    // txnPayLoad?.ownerAccountId ||
                    // (startDate && endDate)
                    txnHistorytype === "internalTxnHistory"
                      ? txnHistorytype === "internalTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "#F05A6A"
                        : "#727272"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "credit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "#F05A6A"
                        : "#727272"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "debit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.beneAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "#F05A6A"
                        : "#727272"
                      : handleFilterApply()
                      ? "#F05A6A"
                      : "#727272",
                  cursor:
                    // paymentMethod ||
                    // txnPayLoad?.amount.length > 0 ||
                    // txnPayLoad?.ownerAccountId ||
                    // (startDate && endDate)
                    txnHistorytype === "internalTxnHistory"
                      ? txnHistorytype === "internalTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "pointer"
                        : "default"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "credit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "pointer"
                        : "default"
                      : txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType == "debit"
                      ? txnHistorytype === "ownerDetailTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.beneAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                        ? "pointer"
                        : "default"
                      : handleFilterApply()
                      ? "pointer"
                      : "default",
                }}
              >
                Apply
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            // onClose={toggleSidebar(false)}
            className={`internalTxnviewallsidebar ${
              isOpen ? "open" : "closed"
            }`}
            style={{
              backgroundColor: "#314c7f",
              pointerEvents: isViewModalOpen ? "none" : "",
              position:
                txnHistorytype === "internalTxnHistory" ||
                txnHistorytype === "ownerDetailTxnHistory"
                  ? "absolute"
                  : "fixed",
              marginTop:
                txnHistorytype === "internalTxnHistory" ||
                txnHistorytype === "ownerDetailTxnHistory"
                  ? "60px"
                  : "175px",
            }}
          >
            <div
              className="internalTxnviewallFilterDiv pl-5"
              style={{
                visibility: isOpen ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
                opacity: isOpen ? "1" : "0",
                borderTop: "2px solid rgb(29, 58, 109)",
              }}
            >
              <div className="internalTxnFilterSection1">
                <span className="text-[#ff5860] font-normal text-[14px]">
                  Filter{" "}
                </span>

                {!isDropdownOpen && (
                  <div className="relative w-[200px] mt-[7px]">
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Payment method
                    </span>

                    <div
                      className="internalTxn_payment_select mt-[10px]"
                      ref={paymentDropdownRef}
                      onClick={(e) => {
                        setDropdownPaymentOpen(!isDropdownPaymentOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        height: isDropdownPaymentOpen ? "160px" : "35px",
                        outline: isDropdownPaymentOpen
                          ? "1px solid #E25869"
                          : "",
                        transition: isDropdownPaymentOpen ? "none" : "",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                        position: "absolute",
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="paymentTxn_category_select_scroll_line"
                          style={{
                            display: isDropdownPaymentOpen ? "block" : "none",
                          }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: isDropdownPaymentOpen ? "9px" : "15px",
                            color: paymentMethod ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {paymentMethod === "" ? "Select type" : paymentMethod}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: isDropdownPaymentOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {isDropdownPaymentOpen && (
                        <div className="bg-[#667CA3] absolute top-12 flex flex-col items-start rounded-[10px] w-full h-[82px]">
                          {["All", "NEFT", "RTGS"].map((settlement) => (
                            <div
                              key={settlement}
                              className="flex w-[208px] h-[35px] hover:bg-[#506994] cursor-pointer rounded-[5px] border-l-transparent ml-1"
                              style={{
                                backgroundColor:
                                  paymentMethod === settlement && paymentMethod
                                    ? "#3A5686"
                                    : "",
                                padding: "0px 10px",
                              }}
                              onClick={(e) => {
                                handleSettlementClick(settlement);
                                setIsDropdownOpen(false);
                              }}
                            >
                              <span className="pl-2 py-2.5 text-[#dadada] text-xs truncate w-[170px] ">
                                {settlement}
                              </span>
                              <div
                                style={{
                                  display:
                                    paymentMethod == settlement
                                      ? "flex"
                                      : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "14px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="internalTxnFilterSection2 pl-[30px] mt-[32px]">
                <div className="relative w-[200px]">
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Destination account
                  </span>
                  <div
                    ref={viewModalRef}
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[216px] mt-[5px]"
                    style={{
                      opacity:
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit"
                          ? "0.5"
                          : currentStatus === "require approval"
                          ? "0.5"
                          : "0.5",
                      cursor:
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit"
                          ? "default"
                          : currentStatus === "require approval"
                          ? "default"
                          : "default",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      // onClick={handleOpenViewModalOwner}
                      onClick={() => {
                        if (
                          txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType === "credit"
                        ) {
                          return;
                        } else {
                          toast.info("Coming Soon...", toast_position);
                        }
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType === "credit"
                            ? "default"
                            : currentStatus === "require approval"
                            ? "default"
                            : "default",
                      }}
                    >
                      <span
                        className="internalTxn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#f9f9f9",
                        }}
                      >
                        {txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit" &&
                        accountNumber
                          ? formatAccountNumber(accountNumber)
                          : "Select owner account"}
                        {/* {txnPayLoad?.ownerbankAccountNo === ""
                      ? "Select owner account"
                      : txnPayLoad?.ownerbankAccountNo} */}
                      </span>
                      <img
                        className="internalTxn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="relative w-[200px] mt-[20px]">
                  <span
                    style={{
                      color: "#dadada",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Source account
                  </span>
                  <div
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[216px] mt-[5px]"
                    style={{
                      opacity:
                        txnHistorytype !== "internalTxnHistory" &&
                        txnHistorytype !== "ownerDetailTxnHistory"
                          ? "1"
                          : txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "credit"
                          ? "1"
                          : currentStatus === "require approval"
                          ? "0.5"
                          : "0.5",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      onClick={(e) => {
                        if (
                          txnHistorytype !== "internalTxnHistory" &&
                          txnHistorytype !== "ownerDetailTxnHistory"
                        ) {
                          handleOpenViewModalOwner();
                          e.stopPropagation();
                        } else if (
                          txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType === "credit"
                        ) {
                          handleOpenViewModalOwner();
                          e.stopPropagation();
                        }
                      }}
                      style={{
                        cursor:
                          txnHistorytype === "internalTxnHistory" ||
                          (txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "debit")
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <span
                        className="internalTxn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#f9f9f9",
                        }}
                      >
                        {txnHistorytype === "internalTxnHistory" &&
                        accountNumber
                          ? formatAccountNumber(accountNumber)
                          : txnHistorytype === "ownerDetailTxnHistory" &&
                            balanceType === "debit" &&
                            accountNumber
                          ? formatAccountNumber(accountNumber)
                          : txnPayLoad?.ownerbankAccountNo === ""
                          ? "Select owner account"
                          : formatAccountNumber(txnPayLoad?.ownerbankAccountNo)}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                {/* <div className="relative w-[200px] mt-5">
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                IFSC code
              </span>
              <input
                className="w-[216px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                maxlength="11"
                type="text"
                name="ifscnumber"
                placeholder="Enter IFSC code"
                value=""
                // onChange={(e) => handleTxnSearch(e.target.value)}
              />
            </div>
            <div>
              <p class="internalTxn_number_text">
                Note : Account number and IFSC code <br />
                must be filled to filter by bank details
              </p>
            </div> */}
              </div>

              <div className="internalTxnFilterSection3 pl-[10px] mt-[42px]">
                <span
                  style={{
                    color: "#dadada",
                    width: "62px",
                    height: "17px",
                    font: "normal normal 12px/19px Open Sans",
                    marginBottom: "5px",
                  }}
                >
                  Date range
                </span>
                <div
                  className="internalTxn_Calendar_main_div absolute flex flex-col items-center bg-[#667CA3] top-[86px] z-[9]"
                  // style={{ opacity: dropdownOpen ? 0 : 1 }}
                  ref={dateRef}
                  style={{
                    width: "305px",
                    height: showDatePicker ? "" : "35px",
                    border: showDatePicker ? "1px solid #ff5860" : "none",
                    // borderRadius: showDatePicker ? "10px" : "auto",
                  }}
                >
                  <div
                    className="flex flex-row items-center justify-between rounded-xl bg-[#667CA3]"
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "35px",
                      // border: showDatePicker ? "none" : "1px solid #ff5860",
                      opacity:
                        currentStatus === "require approval" ? "0.5" : "1",
                      cursor:
                        currentStatus === "require approval"
                          ? "default"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      setShowDatePicker(!showDatePicker);
                      setDropdownOpen(false);
                      setIsDropdownOpen(false);
                      setActiveSection("date");
                    }}
                    disabled={
                      currentStatus === "require approval" ? true : false
                    }
                    // style={{ opacity: dropdownOpen ? 0 : 1 }}
                  >
                    <div
                      className="internalTxn_date_heading_div cursor-pointer"
                      style={{
                        borderTopLeftRadius: showDatePicker ? "7px" : "none",
                        borderLeft: showDatePicker
                          ? "1px solid #ff5860"
                          : "none",
                      }}
                      // onClick={() => setShowDatePicker((prev) => !prev)}
                    >
                      <div className="internalTxn_date_heading_left">
                        {startDate ? formatDate(startDate) : "Select from date"}
                      </div>
                    </div>
                    <img
                      src={arrowforward}
                      className="internalTxn_viewall_calendar_icon"
                    />
                    <div
                      className="internalTxn_date_select_div cursor-pointer"
                      style={{
                        borderTopRightRadius: showDatePicker ? "7px" : "none",
                        borderRight: showDatePicker
                          ? "1px solid #ff5860"
                          : "none",
                      }}
                      // onClick={() => setShowDatePicker((prev) => !prev)}
                    >
                      <div className="internalTxn_date_heading_right">
                        {" "}
                        {endDate ? formatDate(endDate) : "Select to date"}
                      </div>
                    </div>
                  </div>
                  {showDatePicker && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <DatePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={handleDateChange}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                        showDatePicker={showDatePicker}
                        yearDropdownRef={yearDropdownRef}
                        dateRef={dateRef}
                        setShowDatePicker={setShowDatePicker}
                        isOpen={isOpen}
                      />
                    </div>
                  )}
                </div>

                <div
                  className="relative w-[200px] mt-[3.4rem]"
                  style={{
                    visibility: showDatePicker ? "hidden" : "",
                  }}
                >
                  <span
                    style={{
                      color: "#dadada",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Amount
                  </span>

                  <div
                    className="w-[305px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" ? "0.3" : "1",
                      outline: "none",
                    }}
                  >
                    &#8377;
                    <input
                      className="ml-[3px] bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] w-[250px]"
                      type="text"
                      placeholder="Enter transaction amount"
                      value={
                        txnPayLoad?.amount.length > 0 ? txnPayLoad?.amount : ""
                      }
                      name="txnamount"
                      onChange={(e) => handleTxnSearch(e.target.value)}
                      onFocus={() => setActiveSection("txnamount")}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="internalTxnFilterbuttonContainer">
                <div
                  className="acc-viewall-clear-filter border border-[#F9F9F9] text-[#f9f9f9] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      txnHistorytype === "internalTxnHistory" &&
                      accountNumber &&
                      selectedOwnerAccId?.length > 0 &&
                      (paymentMethod ||
                        startDate ||
                        endDate ||
                        txnPayLoad?.amount.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        (startDateFilter && endDateFilter))
                    ) {
                      clearFilter();
                    } else if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "credit"
                    ) {
                      if (
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                      ) {
                        clearFilter();
                      }
                    } else if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "debit"
                    ) {
                      if (
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "debit" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.beneAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDateFilter && endDateFilter))
                      ) {
                        clearFilter();
                      }
                    } else if (
                      (txnHistorytype !== "internalTxnHistory" &&
                        !accountNumber &&
                        paymentMethod) ||
                      startDate ||
                      endDate ||
                      txnPayLoad?.amount.length > 0 ||
                      txnPayLoad?.ownerAccountId ||
                      isPaymentMethod?.length > 0 ||
                      txnAmountFilter?.length > 0 ||
                      selectedOwnerAccId?.length > 0 ||
                      (startDateFilter && endDateFilter)
                    ) {
                      clearFilter();
                    }
                  }}
                  style={{
                    cursor:
                      txnHistorytype === "internalTxnHistory"
                        ? txnHistorytype === "internalTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "pointer"
                          : "default"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "credit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedBeneAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "pointer"
                          : "default"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "debit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.beneAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "pointer"
                          : "default"
                        : paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDateFilter && endDateFilter)
                        ? "pointer"
                        : "default",
                    opacity:
                      txnHistorytype === "internalTxnHistory"
                        ? txnHistorytype === "internalTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "1"
                          : "0.6"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "credit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedBeneAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "1"
                          : "0.6"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "debit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.beneAccountId ||
                            (startDate && endDate) ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDateFilter && endDateFilter))
                          ? "1"
                          : "0.6"
                        : paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          (startDateFilter && endDateFilter)
                        ? "1"
                        : "0.6",
                  }}
                >
                  Clear
                </div>
                <div
                  ref={applyBtnRef}
                  className="acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]"
                  onClick={() => {
                    if (txnHistorytype === "internalTxnHistory") {
                      if (
                        txnHistorytype === "internalTxnHistory" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                      ) {
                        filterCategory();
                      }
                    } else if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "credit"
                    ) {
                      if (
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "credit" &&
                        accountNumber &&
                        selectedBeneAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                      ) {
                        filterCategory();
                      }
                    } else if (
                      txnHistorytype === "ownerDetailTxnHistory" &&
                      balanceType === "debit"
                    ) {
                      if (
                        txnHistorytype === "ownerDetailTxnHistory" &&
                        balanceType === "debit" &&
                        accountNumber &&
                        selectedOwnerAccId?.length > 0 &&
                        (paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.beneAccountId ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          (startDate && endDate && isDateConfirmed))
                      ) {
                        filterCategory();
                      }
                    } else if (handleFilterApply()) {
                      filterCategory();
                    }
                    // if (
                    // paymentMethod ||
                    // txnPayLoad?.amount.length > 0 ||
                    // txnAmountFilter.length > 0 ||
                    // txnPayLoad?.ownerAccountId ||
                    // ((startDate || endDate) && isDateConfirmed) ||
                    // isPaymentMethod?.length > 0 ||
                    // txnAmountFilter?.length > 0 ||
                    // selectedOwnerAccId?.length > 0 ||
                    // (startDateFilter && endDateFilter)
                    //   handleFilterApply()
                    // ) {
                    //   filterCategory();
                    // }
                  }}
                  style={{
                    backgroundColor:
                      // paymentMethod ||
                      // txnPayLoad?.amount.length > 0 ||
                      // txnPayLoad?.ownerAccountId ||
                      // (startDate && endDate)
                      txnHistorytype === "internalTxnHistory"
                        ? txnHistorytype === "internalTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "#F05A6A"
                          : "#727272"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "credit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedBeneAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "#F05A6A"
                          : "#727272"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "debit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.beneAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "#F05A6A"
                          : "#727272"
                        : handleFilterApply()
                        ? "#F05A6A"
                        : "#727272",
                    cursor:
                      // paymentMethod ||
                      // txnPayLoad?.amount.length > 0 ||
                      // txnPayLoad?.ownerAccountId ||
                      // (startDate && endDate)
                      txnHistorytype === "internalTxnHistory"
                        ? txnHistorytype === "internalTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "pointer"
                          : "default"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "credit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedBeneAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "pointer"
                          : "default"
                        : txnHistorytype === "ownerDetailTxnHistory" &&
                          balanceType == "debit"
                        ? txnHistorytype === "ownerDetailTxnHistory" &&
                          accountNumber &&
                          selectedOwnerAccId?.length > 0 &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnPayLoad?.beneAccountId ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            (startDate && endDate && isDateConfirmed))
                          ? "pointer"
                          : "default"
                        : handleFilterApply()
                        ? "pointer"
                        : "default",
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InternalTxnFilterSidebar;
