import React, { useState, useEffect } from "react";
import "./index.css";
import successImage from "../../../assets/Graphics/Success page graphic.svg";
import createTransaction from "../../../assets/GeneralIcons/initiate_internal_transaction.svg";
import viewDetails from "../../../assets/GeneralIcons/detail_page_edit detail.svg";
import dashboard from "../../../assets/GeneralIcons/dashboard.svg";
import requireApproval from "../../../assets/PendingIcons/pending_yellow.svg";
import AwaitingVerification from "../../../assets/AwaitingIcons/awaiting _verification_white.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { beneTxnSuccessDetail } from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import merchant from "../../../assets/CategoryIcons/merchant.svg";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import { tooltipClasses, Tooltip } from "@mui/material";
import loader from "../../../assets/LoaderIcons/Loader.gif";

const BeneTxnSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const transaction = useSelector((state) => state.beneTransactionAccounts);
  const beneTxnSuccessDataError = useSelector(
    (state) => state.beneTransactionAccounts.beneTxnSuccessDataError
  );

  const beneTxnSuccessLoading = useSelector(
    (state) => state.beneTransactionAccounts.beneTxnSuccessLoading
  );

  useEffect(() => {
    if (beneTxnSuccessDataError == "Request failed with status code 404") {
      navigate("*");
    }
  }, [beneTxnSuccessDataError]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneTxnSuccessDetail(id));
  }, [dispatch, id]);

  useEffect(() => { }, [transaction]);
  const viewDetailsHandler = () => {
    navigate(`/transaction/beneficiary_transaction/details/${id}`);
  };

  const createAccountHandler = () => {
    navigate("/transaction/beneficiary_transaction/create");
  };

  const dashboardHandler = () => {
    navigate("/transaction/beneficiary_transaction/dashboard");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className="min-h-screen bg-[#457549]">
      <div className="flex justify-center">
        <div
          className={`flex max-w-screen-lg justify-center ${isMobile ? "flex-col" : "flex-row"
            }`}
        >
          <div className={`flex-1 ${isMobile ? "" : "pt-48"}`}>
            <div className="flex min-w-screen  justify-center items-center bg-[#457549]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                  style={{ width: isMobile ? "200px" : "" }}
                />
                <div className="pt-7">
                  {transaction?.beneTxnSuccessData?.transaction[0]
                    .lifecycle_status === "approved" ? (
                    <>
                      <p className="txnMessage_top">
                        Beneficiary transaction has been created and approved.
                      </p>
                      <p className="txnMessage_bottom">
                        It will be auto settled once sufficient balance is
                        available in the source account.
                      </p>
                    </>
                  ) : (
                    <p
                      className="text-xl font-semibold text-[#f9f9f9]"
                      style={{ fontSize: isMobile ? "14px" : "" }}
                    >
                      {transaction?.beneTxnSuccessData?.transaction[0]
                        .lifecycle_status === "settled"
                        ? "Beneficiary transaction settled successfully!"
                        : transaction?.beneTxnSuccessData?.transaction[0]
                            .lifecycle_status === "pending"
                        ? "Beneficiary transaction created successfully and approval is pending."
                        : transaction?.beneTxnSuccessData?.transaction[0]
                            .lifecycle_status === "queued"
                            ? "Beneficiary transaction has been approved. It will be auto settled once sufficient balance is available in the source account."
                            : ""}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`flex-1 ${isMobile ? "" : "pt-32"}`}>
            <div
              className={`flex flex-col  bg-white rounded-t-[32px]  rounded-b-[38px] items-center justify-between ${isMobile ? "mb-2 w-screen" : ""
                }`}
            >
              <div className="w-full flex flex-col gap-1 items-center justify-center pb-2">
                <div className="flex flex-col font-bold pt-5 pb-3 px-7">
                  <div className="text-bold text-2xl text-[#27B882] w-full text-center pb-2">
                    <p
                      className="w-96"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: " nowrap",
                        textTransform: "capitalize",
                        width: isMobile ? "100%" : "",
                      }}
                    >
                      ₹
                      <FormatAmount
                        price={
                          transaction?.beneTxnSuccessData?.transaction[0]
                            ?.amount == undefined
                            ? 0
                            : transaction?.beneTxnSuccessData?.transaction[0]
                              ?.amount
                        }
                      />
                    </p>
                  </div>
                  <div className="holder_name font-semibold text-base text-center text-[#000000]">
                    <div
                      className="w-96"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // whiteSpace: isMobile ? "" : "nowrap",
                        width: isMobile ? "100%" : "",
                      }}
                    >
                      <Tooltip
                        sx={{ zIndex: "2147483647 !important" }}
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title={
                          transaction?.beneTxnSuccessData?.transaction[0]
                            ?.beneficiary_account_name
                        }
                      >
                        {
                          transaction?.beneTxnSuccessData?.transaction[0]
                            ?.beneficiary_account_name
                        }
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="px-7 w-full content-center">
                  <div className="mx-0 dashedLine"></div>
                </div>

                <div className="successDetailText">
                  <div className="flex flex-col space-y-1 text-left">
                    <div className="text-base antialiased">
                      {formatAccountNumber(
                        transaction?.beneTxnSuccessData?.transaction[0]
                          ?.beneficiary_account_bank_acc_no
                      )}
                    </div>
                    <div className="text-success-subText text-xs antialiased">
                      Beneficiary account number
                    </div>
                  </div>

                  <div className="simpleSuccessLine"></div>
                  <div className="flex flex-row justify-between py-0.5">
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base antialiased text-[#1d1d1d]">
                        {
                          transaction?.beneTxnSuccessData?.transaction[0]
                            ?.beneficiary_account_ifsc_code
                        }
                      </div>
                      <div className="text-success-subText text-xs antialiased">
                        IFSC Code
                      </div>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            transaction?.beneTxnSuccessData?.transaction[0]
                              ?.beneficiary_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                  <div className="simpleSuccessLine "></div>
                  <div className="flex flex-row justify-between py-0.5">
                    <div className="flex flex-col space-y-1 text-left w-4/6">
                      <div className="text-base antialiased"></div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between py-0.2">
                    <div className="flex flex-col space-y-1 text-left w-[200px]">
                      <Tooltip
                        sx={{ zIndex: "2147483647 !important" }}
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title={
                          transaction?.beneTxnSuccessData?.transaction[0]
                            ?.category
                        }
                      >
                        <div className="text-base antialiased truncate">
                          {
                            transaction?.beneTxnSuccessData?.transaction[0]
                              ?.category
                          }
                        </div>
                      </Tooltip>
                      <div className="text-success-subText text-xs antialiased">
                        Transaction category
                      </div>
                    </div>
                    <div className="cat-show flex flex-row justify-end w-2/6">
                      <img src={merchant} />
                    </div>
                  </div>
                  <div className="simpleSuccessLine "></div>
                  <div className="flex flex-row justify-between py-0.5">
                    <div className="flex flex-col space-y-1 text-left w-4/6">
                      <div className="text-base antialiased">
                        {transaction?.beneTxnSuccessData?.transaction[0]?.owner_account_bank_acc_no
                          .toString()
                          .substring(0, 4)}
                        ••••
                        {transaction?.beneTxnSuccessData?.transaction[0]?.owner_account_bank_acc_no
                          .toString()
                          .substring(
                            transaction?.beneTxnSuccessData?.transaction[0]
                              ?.owner_account_bank_acc_no.length - 4
                          )}
                      </div>
                      <div className="text-success-subText text-xs antialiased">
                        Source account number
                      </div>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5  mt-[13px]">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            transaction?.beneTxnSuccessData?.transaction[0]
                              ?.owner_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                  <div className="simpleSuccessLine"></div>
                  {transaction?.beneTxnSuccessData?.transaction[0]
                    .lifecycle_status !== "queued" ||
                    transaction?.beneTxnSuccessData?.transaction[0]
                      .lifecycle_status !== "settled" ? (
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <div className="text-bold antialiased">
                          {transaction?.beneTxnSuccessData?.transaction[0]
                            .lifecycle_status === "pending" &&
                            !beneTxnSuccessLoading
                            ? "Pending"
                            : transaction?.beneTxnSuccessData?.transaction[0]
                              .lifecycle_status === "approved" &&
                              !beneTxnSuccessLoading
                              ? "Created distribution"
                              : ""}
                        </div>
                        <div className="text-success-subText text-xs antialiased">
                          Transaction status
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-10">
                        <img
                          src={
                            transaction?.beneTxnSuccessData?.transaction[0]
                              .lifecycle_status === "pending"
                              ? requireApproval
                              : transaction?.beneTxnSuccessData?.transaction[0]
                                .lifecycle_status === "approved"
                                ? AwaitingVerification
                                : loader
                          }
                          alt="requireapproval"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full divide-x-2  cursor-pointer text-white rounded-4xl bg-[#55985C] flex justify-evenly items-center p-4">
                <div
                  className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center "
                  onClick={createAccountHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"60%"}
                      height={"60%"}
                      src={createTransaction}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Create
                    <br />
                    transaction
                  </p>
                </div>
                <div
                  className="flex flex-col w-2/6 h-3/4 items-center justify-center text-center "
                  onClick={viewDetailsHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"60%"}
                      height={"60%"}
                      src={viewDetails}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    View
                    <br />
                    details
                  </p>
                </div>
                <div
                  onClick={dashboardHandler}
                  className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center  "
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"60%"}
                      height={"60%"}
                      src={dashboard}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Go to <br /> dashboard
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnSuccess;
