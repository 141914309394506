import React, { useEffect, useState } from "react";
import "./index.css";
import { Box } from "@mui/material";
import "./index.css";
import BeneCardLogo from "../BeneCardLogo";
import { useNavigate } from "react-router-dom";
import internalAccountIcon from "../../../../assets/Beneficiary Icons/internal_with_white_circle without fill.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";

const BeneViewAllCard = ({
  data,
  isSidebarOpen,
  typepop,
  handleButtonClick,
  internalAccount,
  associateAccount,
  setOpenInternalAssociate,
  setOpenBeneAssociate,
  associateaccountNumber,
  balanceType,
  associatepanNumber,
  showSearchBar,
  beneAccountId,
  accountType,
  BeneTxnAssocAcct,
}) => {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const benealldata = data;
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const displayedDAata = () => {
    if (associateAccount) {
      if (balanceType === "Pan") {
        return benealldata.filter(
          (item) => item.bank_acc_no !== associateaccountNumber
        );
      }
      if (balanceType === "Bank") {
        return benealldata.filter((item) => item.pan_no !== associatepanNumber);
      }
    } else {
      return benealldata;
    }
  };

  return (
    <>
      {displayedDAata()?.length > 0 ? (
        <>
          {" "}
          <div
            style={{
              overflowY: isSidebarOpen ? "hidden" : "",
              width: typepop === "popup" ? "93.5%" : "",
              paddingBottom: typepop === "popup" ? "180px" : "140px",
            }}
            className={`beneCardEdit ${isSidebarOpen ? "sidebar-open" : ""}`}
          >
            {displayedDAata()?.map((account, index) => (
              <div className="wrap_top_acc_view">
                <Box
                  key={index}
                  className="multiSelectBeneCard relative 5.6xl:w-[265px] 3.1xl:w-[264px]"
                  disabled={
                    associateAccount
                      ? false
                      : typepop === "popup" &&
                        (account?.approval_status === "blocked" ||
                          account?.approval_status === "archived" ||
                          account?.approval_status === "rejected")
                      ? true
                      : false
                  }
                  style={{
                    backgroundColor: "#3a5686",
                    cursor: associateAccount
                      ? "pointer"
                      : typepop === "popup" &&
                        (account?.approval_status === "blocked" ||
                          account?.approval_status === "archived" ||
                          account?.approval_status === "rejected")
                      ? "default"
                      : isSidebarOpen
                      ? "default"
                      : "pointer",
                    opacity: associateAccount
                      ? "1"
                      : typepop === "popup" &&
                        (account?.approval_status === "blocked" ||
                          account?.approval_status === "archived" ||
                          account?.approval_status === "rejected")
                      ? "0.5"
                      : "1",
                  }}
                  onClick={(e) => {
                    if (
                      typepop === "popup" &&
                      (account?.approval_status === "blocked" ||
                        account?.approval_status === "archived" ||
                        account?.approval_status === "rejected") &&
                      !associateAccount
                    ) {
                      return;
                    }

                    if (typepop === "popup" && !associateAccount) {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      }
                      handleButtonClick(account);
                    } else {
                      if (!isSidebarOpen) {
                        const redirectUrl =
                          internalAccount === "internalAccount" ||
                          accountType === "internal"
                            ? `/accounts/internal_account/details/${account.id}`
                            : `/accounts/beneficiary_account/details/${account.id}`;
                        if (
                          accountType === "internal" ||
                          accountType === "beneficiary"
                        ) {
                          setOpenInternalAssociate(false);
                          setOpenBeneAssociate(false);
                        }

                        navigate(redirectUrl);
                      }
                    }
                  }}
                >
                  <div className="viewall-details">
                    <div className="bene-view-all-user">
                      <div
                        className="bene_viewall_name_div"
                        style={{
                          color:
                            account.approval_status === "denied"
                              ? getStatusColor("rejected")
                              : getStatusColor(account.approval_status),
                        }}
                      >
                        <span
                          style={{
                            width:
                              internalAccount === "internalAccount"
                                ? "188px"
                                : "",
                            height:
                              internalAccount === "internalAccount"
                                ? "22px"
                                : "",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {account.name
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>

                        {internalAccount === "internalAccount" ||
                        (associateAccount && accountType === "internal") ? (
                          <div className="bene_viewall_payee">
                            <img src={internalAccountIcon} alt="" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className="bene_viewall-bank-acc-div"
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                      >
                        {" "}
                        {formatAccountNumber(account.bank_acc_no)}
                      </div>
                      <div
                        className="viewall-date-div"
                        style={{ marginLeft: "10px" }}
                      >
                        Account Number
                      </div>
                    </div>
                    <div className="bene_viewall_label-div">
                      <div
                        className={`bene_viewall_assigned ${
                          account.approval_status === "pending"
                            ? "assigned-pending-box"
                            : account.approval_status === "approved"
                            ? "assigned-approved-box"
                            : account.approval_status === "rejected"
                            ? "assigned-denied-box"
                            : account.approval_status === "blocked"
                            ? "assigned-blocked-box"
                            : account.approval_status === "archived"
                            ? "assigned-archived-box"
                            : ""
                        }`}
                      >
                        {account.approval_status === "rejected"
                          ? "Denied"
                          : account.approval_status}
                      </div>
                    </div>
                  </div>
                  <div className="view-all-card-container_acc absolute right-0 mb-0 flex flex-col justify-end ">
                    <BeneCardLogo data={account} type={"account"} />
                  </div>
                  <div className="bene_txn_viewall_hover_content_acc">
                    <div className="pl-[10px] pt-[5px]">
                      <div
                        className="text-sm pb-[4px] first-letter:capitalize"
                        style={{
                          color: getStatusColor(account?.approval_status),
                        }}
                      >
                        {account?.approval_status}
                      </div>
                      <div className="text-[#DADADA] text-[10px] ">
                        Account status
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            className="flex flex-col items-center justify-center m-auto w-full h-full"
            style={{
              marginTop:
                typepop === "popup" && !showSearchBar
                  ? "-6vh"
                  : typepop === "popup" && showSearchBar
                  ? "-9vh"
                  : "",
              height:
                typepop === "popup" && !showSearchBar
                  ? ""
                  : typepop === "popup" && showSearchBar
                  ? "100%"
                  : "",

              overflowY: "scroll",
              paddingBottom: "12vh",
              paddingTop: associateAccount ? "22vh" : "10vh",
              scrollbarWidth: "none",
            }}
          >
            <img
              src={noaccount}
              alt="No accounts found"
              className="bene-acc-no-acc-image"
              style={{
                height:
                  typepop === "popup" && showSearchBar ? "220px" : "230px",
              }}
            />
            <p className="bene-acc-no-beneaccount-text">No accounts found.</p>
          </div>
        </>
      )}
    </>
  );
};

export default BeneViewAllCard;
